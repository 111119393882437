import React from "react";
import { StaticImage  } from "gatsby-plugin-image"

import LayoutSub from "../../components/layoutSub";
import SEO from "../../components/seo";
import ExLink from "../../components/exLink";


function Profile({ location, pageContext }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "About Us";
  const description = "About Us.  We are one of the leading osteopathy providers in Nishiogikubo, Suginami-ku.";

  return (
    <LayoutSub crumbs={crumbs} pathname={pathname} lang='en'>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
        lang="en"
      />

      <section>
      <h1 className=" my-2 heading-b">Hello&nbsp;&#xa1;Hola&#x21;</h1>
      <p>Thank you for coming to out site.  I can&apos;t see your face, but I&apos;m glad to meet you.</p>
      <p>I&apos;m Tomoko Hashimoto.  I&apos;m treating and preventing health problems using a method called osteopathy.</p>

        <div className="overflow-hidden mb-6" >
          <StaticImage
            src="../../images/profile.jpg"
            alt="西荻窪の整体ぺんぎん堂"
            placeholder="blurred"
            layout="constrained"
            width={260}
            className="max-w-2/5 xs:max-w-10 float-left mr-5 my-3 ml-2"
          />


          <p>Osteopathy uses a drug-free, non-invasive form of manual medicine, which means both of body examination and treatment are carried out with the hands.  It focuses on the health of the whole body, not just the injured or affected part, so it improves not only issues in muscles or joints but it also positively affect the body&apos;s nervous, circulatory, and lymphatic systems.
</p>

        </div>
        
        <h2 className="heading-l">What made me to start to study Osteopathy?</h2>
        <div className="overflow-hidden" >
        <StaticImage
          src="../../images/bear-mother.png"
          alt="Bear Mother"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />

<p className="text-lg">Getting a bodywork session</p>  

<p>I had a bad posture since I was a little child.  I hanged from an exercising bar trying to make my posture better, since I was told there might be some sings of scoliosis.  I envied a short and pretty friend because I was tall and played only a mother bear in a school play.  That might be the reason that I got a habit to round my back.&#128531;</p>

<p>
My posture did not get better, however fortunately I didn&apos;t get scoliosis and grow without feeling back pain.  Then I got a bodywork session to make my posture better.  The session triggered my interest in the manual therapy field.</p>

</div>

<p className="text-lg">Learning the basics of manual therapy</p>
<p>I started taking seminars of myofascia release for beginners, Craniosacral therapy, visceral manipulation and so on.  I had taken some courses and tried doing it to my family and friends while working as an engineer for some years.</p> 

<div className="overflow-hidden" >
        <StaticImage
          src="../../images/seed-leaf.png"
          alt="seed"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-right mr-5 my-3 ml-2"
        />

<p>It was so difficult to improve my skills with just taking short courses, so I decided to join a long one.  For a year, I went to a bodywork class every Friday after work.  The class was for those who aim to become a professional Body therapists, but I felt like it&apos;s missing something.</p>

<p>Sometimes my family member&apos;s back pain or quality of sleep was improved thanks to the technique I learned in the class.  But I was not so sure what I really did or which part of his body responded what I did and I started feeling like knowing more.  I came to think I might make more changes on their body or health if I learn more.  I came to want to study more after I went to the class for a year.</p>
</div>

<p className="text-lg">Decision</p>

<p> 	&ldquo; I want to learn bodywork from the basics as a full-time student.  But I like my current job, although sometimes I felt stressed.   Won&apos;t I regret if I throw away my career I built?  Can I keep up with the class?  Most of the students might be much younger than me... &rdquo;It was not easy for me to decide.</p>  

<div className="overflow-hidden mb-6" >
        <StaticImage
          src="../../images/decision.png"
          alt="Decision to study osteopathy"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />



<p>I wasn&apos;t really able to take the first step forward.  The word which a lady I met in a bodywork workshop gave me,  	&ldquo; Your hands are good for being a therapist &rdquo;  and the word one of my friend gave me  	&ldquo; Age doesn&apos;t matter.  Do what you want to do&#x21;  &rdquo; encouraged me to start a new career.</p>

<p>They has made me what I am today.  I&apos;m really thankful to them.</p>

<p>Then, I can&apos;t help starting a new career, I enrolled in Japan College of Osteopathy.  I had studied osteopathy for three years in the school including a year training as an intern. I also attended dissection class and training course in U.S.</p>

<p>I am an assistant instructor of visceral manipulation in the school I graduated since 2020.  It took me so long to finally make a decision but I think I made a good and right decision.</p>

</div>

<h2 className="heading-l">Why osteopathy?</h2>

<p>It&apos;s a bodywork session called Rolfing&reg; which made me interested in a bodywork. In the Rolfing session, the client needs to participate more than in a typical massage session. </p>

<p>In the Rolfing&reg; session, I was expected to observe and feel myself.</p>

<p>How I can help my mother who has more bad conditions in her body as she get older?</p>
<p>I thought it&apos;s a bit difficult for my mother to pay such close attention to her feelings or body movement throughout the whole session.</p> 

<p>I think to pay close attention to your own body or feeling is essential to keep yourself being healthy.  I love bodyworks like Rolfing&reg;</p>

<p>However I wanted to help people who is difficult to participate the session in such manner or those who are not good at it.  So, I decided to learn osteopathy.</p>

<p>I would like to provide a osteopathic session like the following:</p>

<ul className="list-disc">
<li>To increase the mobility of joints, to relieve muscle/fascia tension, to enhance the blood and nerve supply to tissues and to help your body&apos;s own healing mechanisms.
   They may also provide advice on posture and exercise to aid recovery, promote health and prevent symptoms recurring.</li>
<li>To let you be appropriately involved and participate the session having your condition in mind</li>
<li>To provide advice on posture and exercise to aid recovery and promote health</li>
</ul>

<h2 className="heading-l">Why osteopathy?  Another reason.</h2>

<p>My favorite bodywork among those I learned in short classes was Craniosacral therapy that uses gentle touch to palpate the bones of the head and sacrum.</p> 

<p>I wondered who started cranial therapy and found out that the origin is osteopathic manipulative treatment developed by William G. Sutherland, DO(Doctor of Osteopathic Medicine).</p> 

<p>I&apos;d never heard of &ldquo;Osteopathy&rdquo; and I started reading books on it and took an osteopathy session.  Then, I was fascinated by</p> 

<ul className="list-disc">
<li>A profound contribution to the practice of health which osteopathy offers</li>  
<li>A wide range of hands-on Osteopathic techniques</li>
</ul>  


<p  className="mb-6">I have to get prepared to keep on learning even after graduating, even so I started thinking about learning osteopathy.</p>

<h2 className="heading-l">What I studied in the school</h2>

<p>I studied basics such as anatomy and physiology and also  the hands-on techniques of osteopathic manipulative treatment at Japan College of Osteopathy for 3 years.  I was an intern for 1,430 hours as well.</p>

<p className="mb-6">And besides,I also attended dissection class at West Virginia School of Osteopathic Medicine and training course provided by Zachary J. Comeaux DO.</p>

<hr className="topix-border" />

<p>My motto is &ldquo;being careful and gentle, doing my best and being humble&rdquo;.</p>

<p className="mb-6">If you suffer from back pain, neck pain etc. or have any questions, please feel free to speak to us. (^^)/</p>


<div className="border-2 border-c-acc border-solid rounded bg-grey-50 p-2 mb-6">

          <p className="">Tomoko Hashimoto&nbsp;&nbsp;MRO(J）*</p>
          <ul className="text-sm lg:text-base list-disc m-2">
            <li className="mb-1">graduated from&nbsp;<ExLink href="https://www.joa-jco.com">Japan College of Osteopathy</ExLink></li>
            <li className="mb-1">attended dissection class at&nbsp;<ExLink href="https://www.wvsom.edu">West Virginia School of Osteopathic Medicine</ExLink></li>
            <li className="mb-1">A member of&nbsp;<ExLink href="https://osteopathic.jp/">Japan Osteopathic Academy</ExLink></li>
            <li className="mb-1"><ExLink href="https://jones-scsaj.com/free/members">Jones Strain/CounterStrain Academy Japan</ExLink>Counterstrain competency exam level 1&amp;2</li>
          </ul>
          <p className="text-sm lg:text-base ">
            *) MRO(J) is an abbreviation of Member of Registered Osteopaths（Japan) and is an osteopathic qualification issued by <ExLink href="http://osteopathy.gr.jp/mro/">Japan Osteopathic Federation</ExLink></p>
          </div>

          <h2 className="heading-l">Hobbies</h2>

<p>
  I love to bake and eat breads.  I captured wild yeast from a Chinese quince about 5 years ago and I still baked breads using that yeast.  The homemade bread is very delicious though sometimes it looks like an object like UFO. haha.
</p>

<div className="flex flex-row">
  <div className="m-0.5">
<StaticImage
          src="../../images/bread1.jpg"
          alt="homemade bread"
          placeholder="blurred"
          layout="constrained"
          width={250}
          className=""
        />
  </div>

  <div className="m-0.5">
  <StaticImage
          src="../../images/bread2.jpg"
          alt="homemade bread"
          placeholder="blurred"
          layout="constrained"
          width={250}
          className=""
        />
  </div>
  </div>


<p>
  I was into badminton in my student days.  I played it almost every day when I was in high school.<br />
I I was not a strong player but really enjoyed playing it.<br /> I have&apos;t played it nowadays but I still love to watch the games.
    </p>




      </section>
    </LayoutSub>
      );
}

export default Profile;
