import React from "react";

import ExternalLink from "../static/svg/icon-external-link.inline.svg";

const ExLink = ({ href, ...props }) => (

        <a rel="nofollow noreferrer"
            href={href}
            target="_blank"
            className="underline text-c-main hover-hover:hover:text-c-acc break-all">
            {props.children}
            <ExternalLink className="h-4 w-4 inline-block mr-1 align-textbase" />
        </a>


  );

export default ExLink;






